.chat-root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.chatlist-sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chatlist-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.chatlist-title {
  align-items: center;
  width: 100%;
  text-align: center;
}

/* stylelint-disable */
.str-chat__channel-list {
  flex-grow: 1;
  margin: 0;
  height: 600px;
  width: 400px;
}

.str-chat__channel {
  flex-direction: column;
  flex-grow: 2;
  justify-content: space-between;
  height: 100%;
}

.chatchannel_archived_label {
  align-items: center;
  text-align: center;
  font-weight: bold;
}

/* carousel-styles */
.rec-arrow-right {
  position: absolute;
  right: 7%;
  background: #ccc;
}

.sc-gKXOVf {
  display: none;
}

/*carousel color*/
.rec-arrow:hover,
.rec-arrow:focus,
.rec-arrow {
  background-color: #0e73f6 !important;
  color: #fff !important;
}

.rec-arrow:disabled {
  background-color: #f0ebf8 !important;
  color: #999999 !important;
}

.rec-item-wrapper img {
  object-fit: cover;
}
.rec-item-wrapper {
  width: auto !important;
  margin-right: 15px;
}

/* disabled text */
.MuiInputBase-inputMultiline:disabled {
  -webkit-text-fill-color: #1a2024 !important;
}
